const about_links = [
    {
        text: 'The Civil Service Institute',
        route: '/the-civil-service-institute'
    },
    {
        text: 'The Vision is the Challenge',
        route: '/the-vision-is-the-challenge'
    },
    {
        text: 'Our Solutions',
        route: '/our-solutions'
    },
    {
        text: 'Our Partners',
        route: '/our-partners'
    },
    {
        text: 'Our Promise',
        route: '/our-promise'
    },
]

export default about_links